:root {
  /* COLORS */
  --color-cherry-blossom: #df79c4;
  --color-riptide: #8ce6df;
  --color-iris: #594acc;
  --color-tangerine: #f99b7d;
  --color-thalassophobia: #0d1829;
  --color-ocean: #1c9ffd;

  /* FONTS */
  --font-accent: 'Bebas Neue', cursive;
  --font-primary: 'Roboto', sans-serif;
}

html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

body {
  background: var(--color-thalassophobia);
  color: white;
  margin: 0;
  font-family: var(--font-primary);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.4;
  font-size: 16px;
}

@media (max-width: 480px) {
  body {
    font-size: 14px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-accent);
  font-weight: normal;
  margin: 1em 0;
}

h1 {
  font-size: 2.5em;
}

h2 {
  font-size: 2em;
}

h3 {
  font-size: 1.75em;
}

h4 {
  font-size: 1.5em;
}

h5 {
  font-size: 1.25em;
}

h6 {
  font-size: 1em;
}

a,
a:link,
a:visited {
  color: var(--color-ocean);
  text-decoration: none;
  font-weight: bold;
  border-bottom: 1px solid transparent;
  display: inline-block;
  padding: 0 0 0.25em 0;
  transition: border-bottom-color 0.2s;
}

a:hover {
  border-bottom-color: var(--color-iris);
}

table {
  width: auto;
  margin: 1rem auto;
}

table th,
table td {
  padding: 1rem;
}

table th {
  text-transform: uppercase;
  color: var(--color-cherry-blossom);
}